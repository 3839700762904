import React from "react"
import { Link } from "gatsby"
import { Nav } from "react-bootstrap"
import "./menu.css"
import logo from "../../images/logo.png"

export default () => (
	<div className="navigation">
	 <div class="col-9 col-md-4 mb-3">
<div class="nav-brand">
                        <p class="p-logo text-white">AEONIK THREADS</p>
                    </div>
                    </div>
		<Nav className="flex-column text-space font-weight-bold">
			<Nav.Item>
				<Link to="/">HOME</Link>
			</Nav.Item>
			<Nav.Item>
				<Link to="/">SHOP</Link>
			</Nav.Item>
			<Nav.Item>
				<Link to="/">ABOUT US</Link>
			</Nav.Item>
			<Nav.Item>
				<Link to="/">NEWS</Link>
			</Nav.Item>
			<Nav.Item>
				<Link to="/">CONTACT</Link>
			</Nav.Item>
		</Nav>
	</div>
)
